import React, { useState } from 'react'
import CardSysOverride from './CardSysOverride'


const SysparamOverride = (props) => {
    const { sysparamOverride } = props
    const [selecteItemId, setSelectedItemId] = useState()

    function onExpansionPanelClick(id) {
        setSelectedItemId(id !== selecteItemId ? id : undefined)
    }

    function renderList() {
        const list = sysparamOverride.map(sys => (
            <CardSysOverride 
                key={sys.id} 
                sysparam={sys} 
                onExpansionPanelClick={onExpansionPanelClick}
                selected={selecteItemId === sys.id}
            />
        ))
        return list
    }

    return (
        <div>
            {renderList()}
        </div>
    )
}


export default SysparamOverride
