import React from 'react'
import { connect } from 'react-redux'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import { getIsLogin } from '../redux/selectors/auth'
// components
import LoginPage from '../components/pages/login'
import AppBar from '../components/widgets/appBar_search'
import AppMenu from '../components/widgets/app_menu'
import SettingPage from '../components/pages/setting'


const AppLayout = (props) => {

    const { isLogin } = props

    function getPage(pageName) {
        if (!isLogin) return <Redirect to="login" />
        if (!pageName) return <Redirect to="/" />

        switch (pageName) {
            case 'login':
                return <LoginPage isLogin={isLogin}/>
            case 'home':
                return <div style={{ background: '#df3432', height: 1800 }}>home page</div>
            case 'setting':
                return <SettingPage />
            default:
                break;
        }
    }

    return (
        <BrowserRouter>
            {isLogin && <AppBar />}
            {isLogin && <AppMenu />}
            <Switch>
                <Route exact path="/login">
                    <LoginPage isLogin={isLogin}/>
                </Route>
                <Route exact path="/">
                    {getPage('home')}
                </Route>
                <Route exact path="/setting">
                    {getPage('setting')}
                </Route>
                <Route>
                    {getPage()}
                </Route>
            </Switch>
        </BrowserRouter>
    )
}

const mapStateToProps = (state) => ({
    isLogin: getIsLogin(state)
})

export default connect(mapStateToProps, null)(AppLayout)