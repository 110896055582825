import * as types from '../actionTypes'
import { fromJS } from 'immutable'


const initialState = {
    allSysparam: [],
    updateSysStatus: {}
}

export const setting = (state = initialState, { type, payload }) => {
  	    switch (type) {
            case types.FETCH_ALL_SYSPARAM_SUCCESS: {
                return { ...state, allSysparam: payload }
            }
            case types.FETCH_SYSPARAM_OVERRIDE_SUCCESS: {
                return { ...state, sysparamOverride: payload }
            }
            case types.UPDATE_SYSPARAM_OVERRIDE_START: {
                const { updateSysStatus } = state
                updateSysStatus[payload.name] = 'progress'
                return { ...state, updateSysStatus }
            }
            case types.UPDATE_SYSPARAM_OVERRIDE_SUCCESS: {
                // payload: { accountId, name, value1, value2, text }
                const { allSysparam } = state
                const sysIndex = allSysparam.findIndex(sys => sys.name === payload.name)
                if (sysIndex === -1) return state
                allSysparam[sysIndex] = { ...allSysparam[sysIndex], ...payload }

                return { ...state, allSysparam, updateSysStatus: '' }
            }
            case types.UPDATE_SYSPARAM_OVERRIDE_FAILURE: {
                return { ...state, updateSysStatus: 'failed' }
            }  
  		  default:
  	    	  return state
  	}
}

export default setting
