
// auth
export const LOGIN_START = 'login_start'
export const LOGIN_SUCCESS = 'login_success'
export const LOGIN_FAILURE = 'login_failure'
export const LOG_OUT = 'log_out'

// all sys param base
export const FETCH_ALL_SYSPARAM_START = 'fetch_all_sysparam_start'
export const FETCH_ALL_SYSPARAM_SUCCESS = 'fetch_all_sysparam_success'
export const FETCH_ALL_SYSPARAM_FAILURE = 'fetch_all_sysparam_failure'

// sys param override
export const FETCH_SYSPARAM_OVERRIDE_START = 'fetch_sysparam_override_start'
export const FETCH_SYSPARAM_OVERRIDE_SUCCESS = 'fetch_sysparam_override_success'
export const FETCH_SYSPARAM_OVERRIDE_FAILURE = 'fetch_sysparam_override_failure'

// update sysparam override
// sys param override
export const UPDATE_SYSPARAM_OVERRIDE_START = 'update_sysparam_override_start'
export const UPDATE_SYSPARAM_OVERRIDE_SUCCESS = 'update_sysparam_override_success'
export const UPDATE_SYSPARAM_OVERRIDE_FAILURE = 'update_sysparam_override_failure'

// dashboard
export const OPEN_APP_MENU = 'open_app_menu'
export const CLOSE_APP_MENU = 'close_app_menu'
export const CHANGE_PAGE = 'change_page'

// filter
export const SET_SEARCH = 'set_search'
export const CLEAR_SEARCH = 'clear_search'
