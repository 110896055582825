import configApi from '../../config'
import { getAxios } from '../../utils/myAxios'
import createUUID from '../../utils/uuidCreator'
import * as types from '../actionTypes'



export const fetchAllSysparam = () => (dispatch, getState) => {
    dispatch({ type: types.FETCH_ALL_SYSPARAM_START })
    getAxios().get(configApi.URL.SETTING.GET_ALL_SYSPARAM)
        .then(({ data }) => {
            // TODO get the descriptions for sysparam
            let list = data.data.map(sys => ({ ...sys, id: createUUID()}))

            // test sys override (trackingLimitations)
            const accountId = getState().auth.accountId
            list = list.map(sys => ({ ...sys, accountId: (Math.random() < 0.1) ? accountId : undefined }))
            list = list.map(sys => {
                if (sys.name === 'trackingLimitations') {
                    return { ...sys, accountId }
                }
                return sys
            })
            dispatch({
                type: types.FETCH_ALL_SYSPARAM_SUCCESS,
                payload: list
            })
        })
        .catch(({ message }) => {
            dispatch({
                type: types.FETCH_ALL_SYSPARAM_FAILURE,
                payload: message
            })
        })
}

export const fetchAccountSysParamOverride = () => (dispatch) => {
    dispatch({ type: types.FETCH_SYSPARAM_OVERRIDE_START })
    getAxios().get(configApi.URL.SETTING.GET_SYSPARAM_OVERRIDE)
        .then(({ data }) => {
            dispatch({
                type: types.FETCH_SYSPARAM_OVERRIDE_SUCCESS,
                payload: data.data
            })
        })
        .catch(({ message }) => {
            dispatch({
                type: types.FETCH_SYSPARAM_OVERRIDE_FAILURE,
                payload: message
            })
        })
}

export const updateSysParamOverride = ({ accountId, name, value1, value2, text }) => (dispatch) => {
    console.log('updateSysParamOverride', { accountId, name, value1, value2, text })
    dispatch({ type: types.UPDATE_SYSPARAM_OVERRIDE_START, payload: { name } })
    dispatch({
        type: types.UPDATE_SYSPARAM_OVERRIDE_SUCCESS,
        payload: { accountId, name, value1, value2, text }
    })
    // getAxios().post(configApi.URL.SETTING.GET_SYSPARAM_OVERRIDE)
    //     .then(({ data }) => {
    //         dispatch({
    //             type: types.UPDATE_SYSPARAM_OVERRIDE_SUCCESS,
    //             payload: { accountId, name, value1, value2, text }
    //         })
    //     })
    //     .catch(({ message }) => {
    //         dispatch({
    //             type: types.UPDATE_SYSPARAM_OVERRIDE_FAILURE,
    //             payload: message
    //         })
    //     })
}