import React from 'react';
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getIsAppMenuOpen } from '../../../redux/selectors/dashboard'
import { openAppMenu, changePage } from '../../../redux/actions/dashboard'
import { pages } from '../../../constants'

import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import SettingsIcon from '@material-ui/icons/Settings';
import HomeIcon from '@material-ui/icons/Home';

const useStyles = makeStyles({
    list: {
        width: 250,
    },
    fullList: {
        width: 'auto',
    },
});

function AppMenu(props) {

    const classes = useStyles();

    const toggleMenu = (open) => event => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        props.openAppMenu(open);
    }

    const handleClickItemMenu = (page) => () => {
        props.changePage(page)
    }

    const menuIcon = (key) => {
        switch (key) {
            case 'Sysparam override':
                return (
                    <Link to={pages.SETTING}>
                        <ListItem button onClick={handleClickItemMenu(pages.SETTING)}>
                            <ListItemIcon><SettingsIcon /></ListItemIcon>
                            <ListItemText primary={key} />
                        </ListItem>
                    </Link>
                )
            case 'Home':
                return (
                    <Link to={pages.HOME}>
                        <ListItem button onClick={handleClickItemMenu(pages.HOME)}>
                            <ListItemIcon><HomeIcon /></ListItemIcon>
                            <ListItemText primary={key} />
                        </ListItem>
                    </Link>
                )
            default:
                return (
                    <ListItem button onClick={handleClickItemMenu('')}>
                        <ListItemIcon><InboxIcon /></ListItemIcon>
                        <ListItemText primary={key} />
                    </ListItem>
                )
        }
    }

    const sideList = () => (
        <div
            className={classes.list}
            role="presentation"
            onClick={toggleMenu(false)}
            onKeyDown={toggleMenu(false)}
        >
            <List>
                {['Home', 'Sysparam override', 'All sysparams'].map((text) => (
                    <div key={text}>{menuIcon(text)}</div>
                ))}
            </List>
            <Divider />
            {/* <List>
                {['All mail', 'Trash', 'Spam'].map((text, index) => (
                    <ListItem button key={text}>
                        <ListItemIcon>{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}</ListItemIcon>
                        <ListItemText primary={text} />
                    </ListItem>
                ))}
            </List> */}
        </div>
    );

    return (
        <div style={{ margin: '50px 0 0 0' }}>
            <Drawer open={props.isAppMenuOpen} onClose={toggleMenu(false)}>
                {sideList()}
            </Drawer>
        </div>
    );
}

const mapStateToProps = (state) => ({
    isAppMenuOpen: getIsAppMenuOpen(state)
})

const mapDispatchToProps = {
    openAppMenu,
    changePage
}

export default connect(mapStateToProps, mapDispatchToProps)(AppMenu)
