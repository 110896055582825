
const URL_PROTOCOL_TYPE = {
    HTTP: 'http://',
    HTTPS: 'https://'
}

const URL_RESOURCE_NAME = {
    LOCAL: 'localhost:23039/',
    DEV1: 'ptl-dev-web01.pointerbi.com/fleetcore.api/',
    QA: 'pointerqa-web02.pointerbi.com/fleetcore.api/',
}

const CURRENT_URL_PROTOCOL_TYPE = URL_PROTOCOL_TYPE.HTTP;
// const CURRENT_URL_RESOURCE_NAME = URL_RESOURCE_NAME.LOCAL;
const CURRENT_URL_RESOURCE_NAME = URL_RESOURCE_NAME.DEV1;
// const CURRENT_URL_RESOURCE_NAME = URL_RESOURCE_NAME.QA;

function getFullUrlPath(relativePath) {
    return CURRENT_URL_PROTOCOL_TYPE + CURRENT_URL_RESOURCE_NAME + relativePath;
}

export  const configApi = {
    URL: {
        BASE: '',
        SIGNIN: getFullUrlPath('token'),
        GET_DICTIONARY: getFullUrlPath('api/dictionary'),
        GET_LANGUAGES_LIST: getFullUrlPath('api/site/languages'),
        GET_LOGIN_DICTIONARY: getFullUrlPath('api/dictionary/login'),
        SETTING: {
            GET_SYSPARAM_OVERRIDE: getFullUrlPath('api/account/sysoverride'),
            GET_ALL_SYSPARAM: getFullUrlPath('api/account/sysparamall'),
        },
        GENERAL: {
            LOAD_MARKETING_BANNER: getFullUrlPath('api/site/banners'),
        },
    },
};

export default configApi
