import React, { useState, useEffect } from 'react'
import styled from '@emotion/styled'
import { Typography } from '@material-ui/core'


const colorsStack = [
    'red',
    'blue',
    'yellow',
    'green',
    'grey'
]
const random = () => ~~(Math.random() * colorsStack.length)

const Logo = (props) => {
    const { variant } = props

    const [colors, setColors] = useState([
        colorsStack[random()],
        colorsStack[random()],
        colorsStack[random()],
        colorsStack[random()],
        colorsStack[random()],
        colorsStack[random()],
    ])


    let interval
    useEffect(() => {
        interval = setInterval(setRandomColors, 3000)
        return () => {
            clearInterval(interval)
        }
    }, [])

    function setRandomColors() {
        setColors([
            colorsStack[random()],
            colorsStack[random()],
            colorsStack[random()],
            colorsStack[random()],
            colorsStack[random()],
            colorsStack[random()],
        ])
    }

    return (
        <Container>
            <Text color={colors[0]}><Typography display="inline" variant={ variant ? variant : "h1"}>E</Typography></Text>
            <Text color={colors[1]}><Typography display="inline" variant={ variant ? variant : "h1"}>a</Typography></Text>
            <Text color={colors[2]}><Typography display="inline" variant={ variant ? variant : "h1"}>s</Typography></Text>
            <Text color={colors[3]}><Typography display="inline" variant={ variant ? variant : "h1"}>y</Typography></Text>
            <Text color={colors[4]}><Typography display="inline" variant={ variant ? variant : "h1"}>D</Typography></Text>
            <Text color={colors[5]}><Typography display="inline" variant={ variant ? variant : "h1"}>B</Typography></Text>
        </Container>
    )
}

export default Logo

const Container = styled.div`
`
// font-size: xx-large;
const Text = styled.span`
    color: ${p => p.color};
    transition: all 2s
`
